<template>
    <div :class="`card mb-1 mt-4 ${parte.seleccionado?'color':''}`" v-if="this.parte.folio > 0">
        <div class="card-header card-header-icon">
            <div class="card-icon bg-deep-blue" style="padding: 5px 10px 5px;cursor:pointer;">
                <h4 class="card-title text-white" @click="clickTicket">Folio {{ this.parte.folio }} {{ textito }}</h4>
            </div>
        </div>
        <div :class="`card-body`">
            <table class="table mb-1 mx-1">
                <tr>
                    <td>Cliente:</td>
                    <td>
                        <span v-if="this.parte.empresa">{{ this.parte.empresa.razon_social }}</span>
                    </td>
                </tr>
                <tr>
                    <td>Origen:</td>
                    <td>{{ `${parte.origen.nombre} (${parte.tipo_origen_texto})` }}</td>
                </tr>
                <tr>
                    <td>Destino:</td>
                    <td>{{ `${parte.destino.nombre} (${parte.tipo_destino_texto})` }}</td>
                </tr>  

                <!-- <tr v-if="this.parte.tipo_origenes_id == 1">
                    <td>Centro</td>
                    <td>{{ this.parte.origen.nombre }}</td>
                </tr>
                <tr v-if="this.parte.tipo_destinos_id == 1">
                    <td>Centro</td>
                    <td>{{ this.parte.destino.nombre }}</td>
                </tr> -->
                <tr>
                    <td>Recalada:</td>
                    <td>
                        <span v-if="this.parte.recalada">{{ this.moment(this.parte.recalada).format("DD-MM-YYYY HH:mm")}}</span>
                    </td>
                </tr>
                <tr>
                    <td>Amarre Jaula:</td>
                    <td>
                        <span v-if="this.parte.atraco_fondeo">{{ this.moment(this.parte.atraco_fondeo).format("DD-MM-YYYY HH:mm")}}</span>
                    </td>
                </tr>
                <tr>
                    <td>Inicio:</td>
                    <td>
                        <span v-if="this.parte.inicio_faena">{{ this.moment(this.parte.inicio_faena).format("DD-MM-YYYY HH:mm")}}</span>
                    </td>
                </tr>
                <tr>
                    <td>Termino:</td>
                    <td>
                        <span v-if="this.parte.termino_faena">{{ this.moment(this.parte.termino_faena).format("DD-MM-YYYY HH:mm")}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<style scoped>
  .table th, .table td{
    padding: 0;
  }
  .card .card-header.card-header-icon .card-title{
    margin-top: 0;
  }
  .card .card-body{
    padding: 0;
  }

  .color{
    background-color: #ade8f4;
  }
</style>
<script>
export default {
    props: ['parte'],
    methods: {
        clickTicket(){
            this.$emit('clicked', this.parte);
        }
    },
    computed:{
        textito(){
            if([1,3,4].includes(this.parte.tipopartes_id)){
                return this.parte.es_descarga == 0? '(CARGA)':'(TRASLADO/DESCARGA)' //cosecha, smolt, mortalidad
            }
            if([5,6].includes(this.parte.tipopartes_id)){
                return '(CARGA/DESCARGA)' //baño o vacuna
            }
            return ''
        }
    },
    mounted(){
        //console.log(this.parte);
    }
}
</script>