<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="card">
          <div class="card-header card-header-icon">
            <div class="card-icon">
              <i class="material-icons">sticky_note_2</i>
            </div>
            <h3 class="card-title">Folios Temporales Faena de Mortalidad</h3>
          </div>
          <div class="card-body">
            <v-row>
              <v-col md="4" v-for="parte in temporales" v-bind:key="parte.id">
                <TicketBitacoraNew v-bind:parte="parte" @clicked="seleccionParte" />
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- <pre>{{ mostrar }}</pre> -->
      </v-col>
    </v-row>



    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <!-- BITACORA DE CARGA -->
          <v-expansion-panel v-show="mostrar.carga">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              <div>
                BITACORA DE CARGA MORTALIDAD
                <v-icon color="orange" title="Guadado Temporal" v-if="carga.temporal == 1">mdi-clock</v-icon>
                <v-icon color="green" title="Guadado Definitivo" v-if="carga.temporal == 0">mdi-check-circle</v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="2">
                  <!-- <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Folio Parte"
                    label="Folio Parte"
                    v-model="parte.folio"
                    dense
                    disabled
                  >
                  </v-text-field> -->
                  <v-chip>{{ `FOLIO: ${parte.folio}` }}</v-chip>
                </v-col>
              </v-row>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Parámetros medioambientales en centro previo a la carga
                </legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-temperature-celsius" placeholder="Temperatura (°C)" suffix="° C"
                      v-model="carga.temperatura_centro" dense type="number">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="Oxigeno (mg/L)" suffix="mg/L"
                      v-model="carga.oxigeno_centro" dense type="number">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="Saturación (%)" v-model="carga.saturacion_centro"
                      suffix="%" dense type="number">
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>

              <fieldset class="px-2 py-2 elevation-3" v-if="carga.es_nueva">
                <legend class="rounded-sm px-2 py-1">
                  Información General
                </legend>
                <v-row class="mx-0">
                  <!-- <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho" label="Guia Despacho"
                      v-model="carga.guia_despacho" disabled>
                    </v-text-field>
                  </v-col> -->
                  <v-col cols="6">
                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fechaGuiaFormatted" label="Fecha guía de despacho"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="carga.fecha_despacho" @input="menu1 = false" locale="es-cl"
                        prev-icon="chevron_left" next-icon="chevron_right"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-select v-model="carga.especies_id" :items="especies" item-text="nombre" item-value="id"
                      label="Especie" placeholder="Seleccione especie"></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-swim" placeholder="Días de ayuno" label="Días de ayuno"
                      v-model="carga.dias_ayuno" type="text" dense>
                    </v-text-field>
                  </v-col>

                  <!--PARAMETROS-->
                  <v-col cols="4" v-for="p in parameters" :key="`par_${p.id}`">
                    <v-select v-model="p.valor" prepend-icon="mdi-form-select" :items="p.opciones" :label="p.nombre"
                      item-text="nombre" item-value="id" dense></v-select>
                  </v-col>
                  <!--PARAMETROS-->


                  <!--<v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-swim"
                      placeholder="Natacion en bodegas"
                      label="Natacion en bodegas"
                      v-model="carga.natacion"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-air-conditioner"
                      placeholder="Boqueo en bodegas"
                      label="Boqueo en bodegas"
                      v-model="carga.boqueo"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-fish"
                      placeholder="Descamacion"
                      label="Descamacion"
                      v-model="carga.descamacion"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-rounded-corner"
                      placeholder="Peces esquinados"
                      label="Peces esquinados"
                      v-model="carga.esquinados"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-barcode"
                      placeholder="Ataques de lobos"
                      label="Ataques de lobos"
                      v-model="carga.lobos"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-medical-bag"
                      placeholder="Peces con heridas"
                      label="Peces con heridas"
                      v-model="carga.heridas"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-gate"
                      placeholder="Mortalidad en cerco"
                      label="Mortalidad en cerco"
                      v-model="carga.mortalidad_cerco"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>-->


                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello babor" label="N° sello babor"
                      v-model="carga.sello_babor" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello central" label="N° sello central"
                      v-model="carga.sello_central" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello estribor" label="N° sello estribor"
                      v-model="carga.sello_estribor" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 1" label="N° sello Yoma 1"
                      v-model="carga.sello_yoma1" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 2" label="N° sello Yoma 2"
                      v-model="carga.sello_yoma2" dense>
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>

              <fieldset class="px-2 py-2 elevation-3" v-if="!carga.es_nueva">
                <legend class="rounded-sm px-2 py-1">
                  Información General
                </legend>
                <v-row class="mx-0">
                  <!-- <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho" label="Guia Despacho"
                      v-model="carga.guia_despacho" disabled>
                    </v-text-field>
                  </v-col> -->
                  <v-col cols="6">
                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fechaGuiaFormatted" label="Fecha guía de despacho"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="carga.fecha_despacho" @input="menu1 = false" locale="es-cl"
                        prev-icon="chevron_left" next-icon="chevron_right"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-select v-model="carga.especies_id" :items="especies" item-text="nombre" item-value="id"
                      label="Especie" placeholder="Seleccione especie"></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-swim" placeholder="Días de ayuno" label="Días de ayuno"
                      v-model="carga.dias_ayuno" type="text" dense>
                    </v-text-field>
                  </v-col>




                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-swim" placeholder="Natacion en bodegas" label="Natacion en bodegas"
                      v-model="carga.natacion" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-air-conditioner" placeholder="Boqueo en bodegas"
                      label="Boqueo en bodegas" v-model="carga.boqueo" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-fish" placeholder="Descamacion" label="Descamacion"
                      v-model="carga.descamacion" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-rounded-corner" placeholder="Peces esquinados"
                      label="Peces esquinados" v-model="carga.esquinados" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Ataques de lobos" label="Ataques de lobos"
                      v-model="carga.lobos" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-medical-bag" placeholder="Peces con heridas" label="Peces con heridas"
                      v-model="carga.heridas" counter="20" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-gate" placeholder="Mortalidad en cerco" label="Mortalidad en cerco"
                      v-model="carga.mortalidad_cerco" counter="20" dense>
                    </v-text-field>
                  </v-col>


                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello babor" label="N° sello babor"
                      v-model="carga.sello_babor" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello central" label="N° sello central"
                      v-model="carga.sello_central" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello estribor" label="N° sello estribor"
                      v-model="carga.sello_estribor" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 1" label="N° sello Yoma 1"
                      v-model="carga.sello_yoma1" dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 2" label="N° sello Yoma 2"
                      v-model="carga.sello_yoma2" dense>
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">Lances</legend>
                <v-btn @click="agregarLance" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                <!-- <pre>{{ carga.lances }}</pre> -->
                <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Número
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Hora Inicio
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" colspan="4">
                          Parámetros Ambientales
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Número de Peces en lance
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Hora Término
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Tiempo Carga Efectivo
                        </th>
                        <th class="text-center blue-grey darken-4 white-text" rowspan="2">
                          Acciones
                        </th>
                      </tr>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora medición
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Oxigeno (mg/L)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Saturacion (%)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          T (°C)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="lance in carga.lances" :key="lance.key">
                        <td style="border-right: 1px solid black;">
                          {{ lance.numero }}
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-datetime-picker label="Inicio (Fecha Hora)" :key="lance.key" v-model="lance.hora_inicio"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }" @input="calcularCargaEfectiva(lance)">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-datetime-picker label="Medicion (Fecha Hora)" :key="lance.key" v-model="lance.hora_medicion"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }" disabled>
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-text-field v-model="lance.oxigeno" suffix="mg/L" type="number" disabled></v-text-field>
                        </td>
                        <td>
                          <v-text-field v-model="lance.saturacion" suffix="%" type="number" disabled></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-text-field v-model="lance.temperatura" suffix="° C" type="number" disabled></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-text-field v-model="lance.peces" type="number"></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-datetime-picker label="Termino (Fecha Hora)" :key="lance.key" v-model="lance.hora_termino"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }" @input="calcularCargaEfectiva(lance)">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-text-field v-model="lance.carga_efectiva" type="text" disabled></v-text-field>
                        </td>
                        <td>
                          <a @click="eliminarLance(lance)"><span class="material-icons">delete</span></a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">Bodegas</legend>
                <!-- <v-btn @click="dialog = true" color="green"
                  ><v-icon>exposure_plus_1</v-icon></v-btn
                > -->
                <v-btn @click="agregarBodega" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                <v-simple-table dense class="mx-2 my-2 elevation-3">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          N°
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Bodega
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Número de Peces
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Peso Promedio (Kg)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Biomasa Estimada (Kg)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Densidad de Carga (Kg/m3)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          N° Lance
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Jaula
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="detalle in carga.bodegas" :key="detalle.numero">
                        <td class="text-center">{{ detalle.numero }}</td>
                        <td class="text-center">
                          <v-select v-model="detalle.bodegas_id" :items="bodegas" item-text="nombre" item-value="id"
                            label="Bodega" placeholder="Seleccione bodega"></v-select>
                        </td>
                        <!-- <td class="text-center">{{ detalle.numero }}</td>
                        <td class="text-center">{{ detalle.bodega.nombre }}</td> -->
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.peces" type="number"
                            @input="calcularBiomasa(detalle)"></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.peso" type="number"
                            @input="calcularBiomasa(detalle)"></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.biomasa" type="number"
                            disabled></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-right" v-model="detalle.densidad" type="number"
                            disabled></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-center" v-model="detalle.lances"></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field class="input-center" v-model="detalle.jaula" type="text"></v-text-field>
                        </td>
                        <td class="text-center">
                          <a @click="eliminarBodega(detalle)"><span class="material-icons">delete</span></a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2">Total/Prom</td>
                        <td>
                          <v-text-field class="input-right" v-model="totalPeces" type="number" readonly></v-text-field>
                        </td>
                        <td>
                          <v-text-field class="input-right" v-model="totalPeso" type="number" readonly></v-text-field>
                        </td>
                        <td>
                          <v-text-field class="input-right" v-model="totalBiomasa" type="number" readonly></v-text-field>
                        </td>
                        <td>
                          <v-text-field class="input-right" v-model="totalDensidad" type="number" readonly></v-text-field>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </fieldset>
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="1000" label="Observaciones" v-model="carga.observaciones"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-text-field label="Nombre Jefe de Centro" placeholder="Nombre Jefe de Centro"
                    v-model="carga.jefe_centro" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="5">
                  <v-select v-if="tecnicos.length > 0" v-model="carga.tecnico_wellboat" :items="tecnicos"
                    item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="carga.tecnico_wellboat" dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- alert bitacora de carga -->
              <v-row v-if="alert_carga.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_carga.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_carga.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn :color="alert_carga.color_boton" elevation="2" small dark
                          @click="cerrar_alert('carga')">Cerrar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_carga.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_carga.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de carga-->
              <v-row>
                <v-col cols="4" class="text-left">
                  <v-btn color="" @click="guardarCarga(1)" :disabled="carga.temporal == 0 && termino_cam == null">GUARDAR
                    TEMPORAL</v-btn>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-datetime-picker label="Termino (Fecha Hora) CAM" v-model="termino_cam" dateFormat="dd-MM-yyyy"
                    :datePickerProps="{
                      locale: 'es-es',
                      prevIcon: 'chevron_left',
                      nextIcon: 'chevron_right',
                    }" :timePickerProps="{ format: '24hr' }" :disabled="false">
                    <template slot="dateIcon">
                      <v-icon>calendar_today</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>access_time</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn color="green" @click="guardarCarga(0)">GUARDAR CARGA</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- BITACORA DE CARGA -->

          <!-- BITACORA DE TRASLADO -->
          <v-expansion-panel v-show="mostrar.traslado">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              <div>
                BITACORA DE TRASLADO
                <v-icon color="green" title="Guadado Definitivo" v-if="traslado.temporal == 0">mdi-check-circle</v-icon>
                <v-icon color="orange" title="Guadado Temporal" v-if="traslado.temporal == 1">mdi-clock</v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="2">
                  <v-chip>{{ `FOLIO: ${parte.folio}` }}</v-chip>
                </v-col>
                <!-- <v-col cols="2">
                  <p>Cliente</p>
                  <p>{{ traslado.empresa}}</p>
                </v-col>
                <v-col cols="2">
                  <p>Centro Carga</p>
                  <p>{{ traslado.centro_carga}}</p>
                </v-col>
                <v-col cols="2">
                  <p>Centro Descarga</p>
                  <p>{{ traslado.centro_descarga}}</p>
                </v-col>
                <v-col cols="2">
                  <p>Hora Inicio</p>
                  <p>{{ traslado.hora_inicio | format_date_spanish }}</p>
                </v-col>
                <v-col cols="2">
                  <p>Hora Término</p>
                  <p>{{ traslado.hora_termino | format_date_spanish }}</p>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col>
                  <v-simple-table>
                    <thead class="bg-deep-blue">
                      <tr>
                        <th class="text-white">Cliente</th>
                        <th class="text-white">Guía Despacho</th>
                        <th class="text-white">Fecha Despacho</th>
                        <th class="text-white">Centro Carga</th>
                        <th class="text-white">Centro Descarga</th>
                        <th class="text-white">Hora inicio</th>
                        <th class="text-white">Hora Término</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ traslado.empresa }}</td>
                        <!-- <td>{{ traslado.guia_despacho }}</td> -->
                        <td>
                          <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho" label="Guia Despacho"
                            v-model="traslado.guia_despacho">
                          </v-text-field>
                        </td>
                        <td>{{ traslado.fecha_despacho | format_fecha }}</td>
                        <td>{{ traslado.centro_carga }}</td>
                        <td>{{ traslado.centro_descarga }}</td>
                        <td>
                          {{ traslado.hora_inicio | format_date_spanish }}
                        </td>
                        <td>
                          {{ traslado.hora_termino | format_date_spanish }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <small>NOTA: Los datos de cliente, guia de despacho, fecha de
                    despacho, centro de carga, centro de descarga, hora de
                    inicio y hora de término son automáticos y se obtienen de la
                    información del parte y bitácora de carga</small>
                </v-col>
              </v-row>
              <!-- <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Mediciones de parámetros
                </legend>
                <v-btn @click="addMedicionTraslado" color="green"
                  ><v-icon>exposure_plus_1</v-icon></v-btn
                >
                <v-simple-table dense class="mx-0 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          rowspan="2"
                          class="text-center blue-grey darken-4 white-text"
                        >
                          Número
                        </th>
                        <th
                          rowspan="2"
                          class="text-center blue-grey darken-4 white-text"
                        >
                          HORA
                        </th>
                        <th
                          colspan="4"
                          class="text-center blue-grey darken-4 white-text"
                          v-for="bodega in bodegas"
                          :key="bodega.id"
                        >
                          {{ bodega.bodega.nombre }}
                        </th>
                        <th
                          rowspan="2"
                          class="text-center blue-grey darken-4 white-text"
                        >
                          Acciones
                        </th>
                      </tr>
                      <tr>
                        <template v-for="i in bodegas.length">
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="i"
                          >
                            O2 (mg/L)
                          </th>
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="i"
                          >
                            PH
                          </th>
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="i"
                          >
                            UV (ml/cm2)
                          </th>
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="i"
                          >
                            T (°C)
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="parametro in traslado.parametros"
                        :key="parametro.numero"
                      >
                        <td>{{ parametro.numero }}</td>
                        <td style="border-right: 1px solid black;width: 180px;">
                          <v-datetime-picker
                            label="Hora Medición"
                            v-model="parametro.hora"
                            dateFormat="dd-MM-yyyy"
                            :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }"
                            :timePickerProps="{ format: '24hr' }"
                          >
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <template v-for="bodega in bodegas">
                          <td :key="bodega.id">
                            <v-text-field
                              v-model="
                                parametro['b' + bodega['bodega'].id].oxigeno
                              "
                              type="number"
                              step="0.1"
                              class="input-right"
                            ></v-text-field>
                          </td>
                          <td :key="bodega.id">
                            <v-text-field
                              v-model="parametro['b' + bodega['bodega'].id].ph"
                              type="number"
                              class="input-right"
                            ></v-text-field>
                          </td>
                          <td :key="bodega.id">
                            <v-text-field
                              v-model="parametro['b' + bodega['bodega'].id].uv"
                              type="number"
                              class="input-right"
                            ></v-text-field>
                          </td>
                          <td
                            style="border-right: 1px solid black;"
                            :key="bodega.id"
                          >
                            <v-text-field
                              v-model="
                                parametro['b' + bodega['bodega'].id].temperatura
                              "
                              type="number"
                              class="input-right"
                            ></v-text-field>
                          </td>
                        </template>
                        <td>
                          <a @click="deleteParametroTraslado"
                            ><span class="material-icons">delete</span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset> -->
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="500" label="Observaciones" v-model="traslado.observaciones"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select v-if="tecnicos.length > 0" v-model="traslado.tecnico_wellboat" :items="tecnicos"
                    item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="traslado.tecnico_wellboat" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Supervisor" placeholder="Supervisor" v-model="traslado.supervisor" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-if="capitanes.length > 0" v-model="traslado.capitan" :items="capitanes" item-text="nombre"
                    item-value="id" label="Nombre Capitán" dense>
                  </v-select>
                  <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                    v-model="traslado.capitan" dense>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- alert bitacora de traslado -->
              <v-row v-if="alert_traslado.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_traslado.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_traslado.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn :color="alert_traslado.color_boton" elevation="2" small dark
                          @click="cerrar_alert('traslado')">Cerrar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_traslado.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_traslado.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de traslado-->

              <v-row>
                <v-col cols="6">
                  <v-btn color="" @click="guardarTraslado(1)" :disabled="traslado.temporal == 0">GUARDAR TEMPORAL</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="guardarTraslado(0)">GUARDAR TRASLADO</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- BITACORA DE TRASLADO -->

          <!-- BITACORA DE DESCARGA A PLANTA -->
          <v-expansion-panel v-show="mostrar.descarga_planta">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              <div>
                BITACORA DE DESCARGA DIRECTA A PLANTA
                <v-icon color="green" title="Guadado Definitivo"
                  v-if="descarga_planta.temporal == 0">mdi-check-circle</v-icon>
                <v-icon color="orange" title="Guadado Temporal" v-if="descarga_planta.temporal == 1">mdi-clock</v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="2">
                  <v-chip>{{ `FOLIO: ${parte.folio}` }}</v-chip>
                </v-col>
                <!-- <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Guia de Despacho"
                    label="Guia de Despacho"
                    v-model="descarga_planta.guia"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaGuiaPlanta"
                        label="Fecha guía de despacho"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="descarga_planta.fecha"
                      @input="menu2 = false"
                      locale="es-cl"
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    placeholder="Cliente"
                    label="Cliente"
                    v-model="descarga_planta.empresa"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    placeholder="Muestreo"
                    label="Muestreo"
                    v-model="descarga_planta.muestreo"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Especie"
                    label="Especie"
                    v-model="descarga_planta.especie"
                    dense
                  ></v-text-field>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field placeholder="Muestreo" label="Muestreo" v-model="descarga_planta.muestreo" dense>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-simple-table>
                    <thead class="bg-deep-blue">
                      <tr>
                        <th class="text-white">Cliente</th>
                        <th class="text-white">Guía Despacho</th>
                        <th class="text-white">Fecha Despacho</th>
                        <th class="text-white">Especie</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="carga in descarga_planta.cargas" :key="carga.id">
                        <td>{{ carga.nombre_empresa }}</td>
                        <td>{{ carga.guia_despacho }}</td>
                        <td>{{ carga.fecha_despacho | format_fecha }}</td>
                        <td>{{ carga.nombre_especie }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <small>NOTA: Los datos de cliente, guia de despacho, fecha de
                    despacho y especie son automáticos y se obtienen de la
                    información del parte y bitácoras de carga</small>
                </v-col>
              </v-row>
              <!-- <pre v-if="descarga_planta.cargas">{{
                descarga_planta.cargas[0].descargas_horas
              }}</pre> -->
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Detalle de Tiempo de Descarga de la Planta
                </legend>
                <v-tabs center-active v-model="tab">
                  <v-tab v-for="carga in descarga_planta.cargas" :key="carga.id">{{ `Carga Guía: ${carga.guia_despacho}`
                  }}</v-tab>
                  <!-- <v-tab v-if="parte1.id > 0">Carga Guia: {{ parte1.carga.guia_despacho }}</v-tab>
                <v-tab v-if="parte2.id > 0">Carga Guia: {{ parte2.carga.guia_despacho }}</v-tab> -->
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="carga in descarga_planta.cargas" :key="carga.id">
                    <v-btn @click="agregarDescargaHora(carga)" class="my-1"
                      color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                    <v-simple-table dense class="mx-0 my-1 elevation-3 text-center">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th rowspan="1" class="text-center blue-grey darken-4 white-text">
                              Número
                            </th>
                            <th rowspan="1" class="text-center blue-grey darken-4 white-text">
                              Hora Inicio
                            </th>
                            <th rowspan="1" class="text-center blue-grey darken-4 white-text">
                              Hora termino
                            </th>
                            <th colspan="1" class="text-center blue-grey darken-4 white-text">
                              Motivo
                            </th>
                            <th colspan="1" class="text-center blue-grey darken-4 white-text">
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(hora, idx) in carga.descargas_horas" :key="idx">
                            <td>{{ hora.numero }}</td>
                            <td style="border-right: 1px solid black;width: 180px;">
                              <v-datetime-picker label="Hora Inicio" v-model="hora.hora_inicio" :key="hora.key"
                                dateFormat="dd-MM-yyyy" :datePickerProps="{
                                  locale: 'es-es',
                                  prevIcon: 'chevron_left',
                                  nextIcon: 'chevron_right',
                                }" :timePickerProps="{ format: '24hr' }">
                                <template slot="dateIcon">
                                  <v-icon>calendar_today</v-icon>
                                </template>
                                <template slot="timeIcon">
                                  <v-icon>access_time</v-icon>
                                </template>
                              </v-datetime-picker>
                            </td>
                            <td style="border-right: 1px solid black;width: 180px;">
                              <v-datetime-picker label="Hora Término" v-model="hora.hora_termino" :key="hora.key"
                                dateFormat="dd-MM-yyyy" :datePickerProps="{
                                  locale: 'es-es',
                                  prevIcon: 'chevron_left',
                                  nextIcon: 'chevron_right',
                                }" :timePickerProps="{ format: '24hr' }">
                                <template slot="dateIcon">
                                  <v-icon>calendar_today</v-icon>
                                </template>
                                <template slot="timeIcon">
                                  <v-icon>access_time</v-icon>
                                </template>
                              </v-datetime-picker>
                            </td>
                            <td>
                              <v-text-field v-model="hora.motivo"></v-text-field>
                            </td>
                            <td>
                              <button @click="quitarDescargaHora(hora, carga)">
                                <span class="material-icons">delete</span>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs-items>
              </fieldset>
              <v-row class="mt-6">
                <v-col cols="4">
                  <v-select v-if="tecnicos.length > 0" v-model="descarga_planta.tecnico_wellboat" :items="tecnicos"
                    item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="descarga_planta.tecnico_wellboat" dense>
                  </v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-select v-if="capitanes.length > 0" v-model="descarga_planta.capitan" :items="capitanes"
                    item-text="nombre" item-value="id" label="Nombre Capitán" dense>
                  </v-select>
                  <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                    v-model="descarga_planta.capitan" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Encargado" placeholder="Encargado" v-model="descarga_planta.encargado" dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="500" label="Observaciones"
                    v-model="descarga_planta.observaciones"></v-textarea>
                </v-col>
              </v-row>

              <!-- alert bitacora de descarga planta -->
              <v-row v-if="alert_descarga_planta.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_descarga_planta.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_descarga_planta.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn :color="alert_descarga_planta.color_boton" elevation="2" small dark
                          @click="cerrar_alert('descarga_planta')">Cerrar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_descarga_planta.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_descarga_planta.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de descarga planta-->

              <v-row>
                <v-col cols="6">
                  <v-btn color="" @click="guardarDescargaPlanta(1)" :disabled="descarga_planta.temporal == 0">GUARDAR
                    TEMPORAL</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="guardarDescargaPlanta(0)">GUARDAR DESCARGA</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- BITACORA DE DESCARGA A PLANTA -->
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- alert finalizar servicio -->
    <v-row v-if="alert_finalizar.mostrar">
      <v-col>
        <v-alert border="left" outlined text :type="alert_finalizar.tipo">
          <v-row>
            <v-col>
              <h4>{{ alert_finalizar.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn :color="alert_finalizar.color_boton" elevation="2" small dark
                @click="cerrar_alert('finalizar')">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="alert_finalizar.errores.length > 0">
            <v-col>
              <ul>
                <li v-for="e in alert_finalizar.errores" :key="e">
                  {{ e }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <!-- alert finalizar servicio-->

    <!-- FINALIZAR FAENA DE MORTALIDAD -->
    <v-row v-if="finalizar_servicio">
      <v-col cols="6">
        <v-file-input v-model="archivos" label="Adjuntar documentación" show-size counter multiple
          prepend-icon="attach_file" chips accept=".pdf"></v-file-input>
      </v-col>
      <v-col cols="12">
        <v-btn color="green" @click="finalizarServicioWellboat" :disabled="archivos.length == 0">FINALIZAR SERVICIO
          WELLBOAT</v-btn>
      </v-col>
    </v-row>
    <!--  -->
  </div>
</template>
<script>
import TicketBitacoraNew from "@/components/TicketBitacoraNew.vue";
import { mapState } from "vuex";

export default {
  data: () => ({
    temporales: [],
    tecnicos: [],
    capitanes: [],
    especies: [],
    parte: {},
    carga: {},
    traslado: {},
    descarga: {},
    descarga_planta: {},
    mostrar: {
      carga: false,
      traslado: false,
      descarga: false,
      descarga_planta: false,
    },
    menu1: false,
    menu2: false,
    alert_carga: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_traslado: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_descarga_planta: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_finalizar: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    url_pdf_carga: null,
    url_pdf_traslado: null,
    url_pdf_descarga: null,
    url_pdf_descarga_planta: null,
    tab: 0,
    archivos: [],
    parameters: [],
    termino_cam: null,
    descargas_horas_ids_eliminar:[]
  }),
  async mounted() {
    this.especies = this.$store.getters["especie/getActive"];
    await this.cargarPartesTemporales();
    await this.loadParameters();
  },
  methods: {
    async loadParameters() {
      let url = `${this.base_url}tipos_bitacoras/${3}/cargar_parametros`
      await this.axios.get(url, this.headers_json).then(r => {
        this.parameters = r.data
      })
    },
    async seleccionParte(parte) {
      // console.log("parte", parte.id);
      this.parte = parte;

      //actualizar parte seleccionado (para mostrar en color visualmente el parte seleccionado)
      let idx = this.temporales.findIndex((t) => t.id == this.parte.id);
      this.temporales.forEach((p, i) => {
        p.seleccionado = i == idx ? true : false;
      });

      //cargando bitacoras del parte
      await this.cargarBitacoras();

      //carga de bodegas del wellboat
      await this.cargarBodegas();


      await this.cargarTecnicos();
      await this.cargarCapitanes();

      // console.log(`es descarga: ${this.parte.es_descarga}`);
    },
    async cargarBitacoras() {
      this.carga = {
        lances: [],
        bodegas: [],
      };
      this.traslado = {};
      this.descarga = {};
      this.descarga_planta = {};

      let url = `${this.base_url}partes/${this.parte.id}/bitacoras`;
      await this.axios.get(url, this.headers_json).then((r) => {
        console.log("data", r.data)
        //bitacora de carga
        this.carga = r.data.carga;
        if (this.parte.termino_cam) {
          this.termino_cam = this.moment(this.parte.termino_cam).toDate();
        }
        this.formatearFechaParaDatepicker("carga");
        this.setearParametros()
        //bitacora de carga

        //bitacora de traslado
        if (r.data.traslado != null) {
          this.traslado = r.data.traslado;
        }
        //bitacora de traslado

        //bitacora de descarga
        if (r.data.descarga != null) {
          this.descarga = r.data.descarga;
        }
        //bitacora de descarga

        //bitacora de descarga directa a planta
        if (r.data.descarga_planta != null) {
          this.descarga_planta = r.data.descarga_planta;
          this.formatearFechaParaDatepicker("descarga_planta");
        }
        //bitacora de descarga directa a planta

        this.mostrar = r.data.mostrar;
      });
    },

    setearParametros() {
      this.parameters.forEach((p, idx) => {
        let par = this.carga.parametros.find(item => item.parametros_id == p.id)
        if (par) {
          this.$set(this.parameters[idx], 'valor', par.parametros_opciones_id)
        }

      });
    },

    formatearFechaParaDatepicker(bitacora = "") {
      if (bitacora == "descarga_planta") {
        //reformateando fechas carga (para mostrar en datepicker)
        this.descarga_planta.cargas.forEach((c) => {
          c.descargas_horas.forEach((h) => {
            h.key = this.generarKey(5);
            h.hora_inicio = this.$options.filters.format_string_a_fecha(
              h.hora_inicio
            );
            h.hora_termino = this.$options.filters.format_string_a_fecha(
              h.hora_termino
            );
          });
        });
      }

      if (bitacora == "carga") {
        //reformateando fechas carga (para mostrar en datepicker)
        this.carga.lances.forEach((l) => {
          l.key = this.generarKey(5);
          l.hora_inicio = this.$options.filters.format_string_a_fecha(
            l.hora_inicio
          );
          l.hora_termino = this.$options.filters.format_string_a_fecha(
            l.hora_termino
          );
        });
      }
    },
    async cargarPartesTemporales() {
      // console.log("cargando partes temporales ...");
      let url = `${this.base_url}partes/temporales/wellboat/${this.user.wellboat.id}/tipo_parte/4`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.temporales = response.data.data;
          if (this.temporales.length > 0) {
            this.seleccionParte(this.temporales[0]);
          }
          //   if (
          //     this.temporales.length > 0 &&
          //     this.user.wellboat != undefined &&
          //     this.user.wellboat.id > 0
          //   ) {
          //     let parte = this.temporales[0];
          //     if (parte) {
          //       this.folio = parte.folio;
          //       this.loadTecnicos();
          //       this.loadCapitanes();
          //       this.carga.parte = parte;
          //       this.loadCargaParte();
          //       this.loadTrasladoParte();
          //       this.loadDescargaParte();
          //     }
          //   }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarLance() {
      let n = this.carga.lances.length;
      let lance = {
        id: null,
        key: this.generarKey(5),
        menu1: false,
        menu2: false,
        menu3: false,
        numero: n + 1,
        hora_inicio: null,
        hora_medicion: null,
        oxigeno: null,
        saturacion: null,
        temperatura: null,
        ph: null,
        peces: null,
        hora_termino: null,
        carga_efectiva: null,
      };
      this.$set(this.carga.lances, n, lance);
    },
    agregarBodega() {
      let n = this.carga.bodegas.length;
      let b = {
        id: null,
        numero: n + 1,
        bodegas_id: null,
        peces: null,
        peso: null,
        biomasa: null,
        densidad: null,
        lances: null,
        jaula: null,
        estanques: null,
        hora_inicio: null,
        hora_termino: null,
      };
      this.carga.bodegas.push(b);
    },
    eliminarLance($event) {
      //const index = this.carga.lances.indexOf(item);
      let idx = this.carga.lances.findIndex((i) => i.numero == $event.numero);
      // console.log(`numero: ${$event.numero}, idx: ${idx}`);
      this.carga.lances.splice(idx, 1);
      let n = 0;
      this.carga.lances.forEach((l) => {
        n += 1;
        l.numero = n;
      });
    },
    eliminarBodega($event) {
      //const index = this.carga.lances.indexOf(item);
      let idx = this.carga.bodegas.findIndex((i) => i.numero == $event.numero);
      // console.log(`numero: ${$event.numero}, idx: ${idx}`);
      this.carga.bodegas.splice(idx, 1);
      let n = 0;
      this.carga.bodegas.forEach((l) => {
        n += 1;
        l.numero = n;
      });
    },
    async cargarTecnicos() {
      let url = `${this.base_url}cargas/tecnicos/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.tecnicos = response.data;
          // this.carga.tecnico_wellboat = this.tecnicos[0];
          // this.traslado.tecnico_wellboat = this.tecnicos[0];
          // this.descarga.tecnico_wellboat = this.tecnicos[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async cargarCapitanes() {
      let url = `${this.base_url}cargas/capitanes/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.capitanes = response.data;
          //this.carga.tecnico_wellboat = this.capitanes[0];
          //this.traslado.tecnico_wellboat = this.capitanes[0];
          //this.descarga.tecnico_wellboat = this.capitanes[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async cargarBodegas() {
      let fecha_viaje = this.parte.recalada? this.parte.recalada : this.parte.zarpe;
      let url = `${this.base_url}wellboats/${this.parte.wellboats_id}/bodegas/${fecha_viaje}`;
      await this.axios.get(url, this.headers_json).then((r) => {
        this.bodegas = r.data.data;
      });
    },
    calcularBiomasa(bodega) {
      let idx = this.carga.bodegas.findIndex((b) => b.numero == bodega.numero);
      let peso = bodega.peso?.toString().replace(",", ".");
      let bod = this.bodegas.find((b) => b.id == bodega.bodegas_id);
      let biomasa = Math.round(bodega.peces * peso);
      let densidad = (biomasa / bod.m3).toFixed(2);
      this.$set(this.carga.bodegas[idx], "biomasa", biomasa);
      this.$set(this.carga.bodegas[idx], "densidad", densidad);
    },

    async guardarCarga(temporal = 1) {
      this.cerrar_alert("carga");
      this.carga.temporal = temporal;
      this.carga.parametros = this.parameters;

      //reformateando fechas
      this.carga.lances.forEach((l) => {
        l.hora_inicio = this.$options.filters.format_fecha_hora(l.hora_inicio);
        l.hora_termino = this.$options.filters.format_fecha_hora(
          l.hora_termino
        );
      });

      this.carga.bodegas.forEach((b) => {
        b.hora_inicio = this.$options.filters.format_fecha_hora(b.hora_inicio);
        b.hora_termino = this.$options.filters.format_fecha_hora(
          b.hora_termino
        );
      });

      let fecha_cam = this.termino_cam ? this.moment(this.termino_cam).format("YYYY-MM-DD HH:mm") : null

      this.$set(this.carga, 'termino_cam', fecha_cam);

      //let url = this.carga.id != null? `${this.base_url}cargas/${this.carga.id}`:`${this.base_url}cargas`
      let url = `${this.base_url}cargas/guardar`;
      await this.axios
        .post(url, this.carga, this.headers_json)
        .then((r) => {
          this.alert_carga.mostrar = true;
          this.alert_carga.tipo = "success";
          this.alert_carga.color_boton = "green";
          this.alert_carga.mensaje = r.data.message;
          this.carga = r.data.data;
          this.formatearFechaParaDatepicker("carga");
          //this.cargarBitacoras();

          this.url_pdf_carga = r.data.url_pdf;
          if (this.url_pdf_carga != null) {
            window.open(`${this.storage_url}${this.url_pdf_carga}`);
          }
        })
        .catch((e) => {
          this.alert_carga.mostrar = true;
          this.alert_carga.tipo = "error";
          this.alert_carga.color_boton = "red";
          this.alert_carga.mensaje = e.response.data.message;
          this.alert_carga.errores = e.response.data.errors;
          this.carga.temporal = 1;
        });
    },

    async guardarTraslado(temporal = 1) {
      this.cerrar_alert("traslado");
      this.traslado.temporal = temporal;
      let url = `${this.base_url}traslados/guardar`;

      await this.axios
        .post(url, this.traslado, this.headers_json)
        .then((r) => {
          this.alert_traslado.mostrar = true;
          this.alert_traslado.tipo = "success";
          this.alert_traslado.color_boton = "green";
          this.alert_traslado.mensaje = r.data.message;
          this.traslado = r.data.data;
          this.url_pdf_traslado = r.data.url_pdf;
          if (this.url_pdf_traslado != null) {
            window.open(`${this.storage_url}${this.url_pdf_traslado}`);
          }
          this.cargarBitacoras()
        })
        .catch((e) => {
          this.alert_traslado.mostrar = true;
          this.alert_traslado.tipo = "error";
          this.alert_traslado.color_boton = "red";
          this.alert_traslado.mensaje = e.response.data.message;
          this.alert_traslado.errores = e.response.data.errors;
          this.traslado.temporal = 1;
        });

      // console.log(`traslado temporal: ${temporal}`);
    },

    async guardarDescargaPlanta(temporal = 1) {
      this.cerrar_alert("descarga_planta");
      this.descarga_planta.temporal = temporal;

      let url = `${this.base_url}descargas_plantas/guardar`;

      //reformateando fechas
      this.descarga_planta.cargas.forEach((c) => {
        c.descargas_horas.forEach((h) => {
          h.hora_inicio_f = this.$options.filters.format_fecha_hora(
            h.hora_inicio
          );
          h.hora_termino_f = this.$options.filters.format_fecha_hora(
            h.hora_termino
          );
        });
      });

      this.descargas_horas_ids_eliminar.forEach(async (id) => {
        await this.axios.delete(`${this.base_url}descargas_horas/${id}`,this.headers_json)
      });

      this.descargas_horas_ids_eliminar = []

      await this.axios
        .post(url, this.descarga_planta, this.headers_json)
        .then((r) => {
          this.alert_descarga_planta.mostrar = true;
          this.alert_descarga_planta.tipo = "success";
          this.alert_descarga_planta.color_boton = "green";
          this.alert_descarga_planta.mensaje = r.data.message;
          this.descarga_planta = r.data.data;
          this.formatearFechaParaDatepicker("descarga_planta");
          this.url_pdf_descarga_planta = r.data.url_pdf;
          if (this.url_pdf_descarga_planta != null) {
            window.open(`${this.storage_url}${this.url_pdf_descarga_planta}`);
          }
        })
        .catch((e) => {
          this.alert_descarga_planta.mostrar = true;
          this.alert_descarga_planta.tipo = "error";
          this.alert_descarga_planta.color_boton = "red";
          this.alert_descarga_planta.mensaje = e.response.data.message;
          this.alert_descarga_planta.errores = e.response.data.errors;
          this.descarga_planta.temporal = 1;
        });
      // console.log(`descarga planta temporal: ${temporal}`);
      // console.log("descarga_planta", this.descarga_planta);
    },

    agregarDescargaHora(carga) {
      let h = {
        id: null,
        numero: carga.descargas_horas.length + 1,
        key: this.generarKey(5),
        partes_id: carga.partes_id,
        cargas_id: carga.id,
        hora_inicio: null,
        hora_termino: null,
        motivo: null,
      };

      let idx = this.descarga_planta.cargas.findIndex((c) => c.id == carga.id);
      this.descarga_planta.cargas[idx].descargas_horas.push(h);
    },

    quitarDescargaHora(hora, carga) {
      console.log(hora)
      let idx_carga = this.descarga_planta.cargas.findIndex((c) => c.id == carga.id);
      let idx_descarga_hora = this.descarga_planta.cargas[idx_carga].descargas_horas.findIndex(d => d == hora);
      let id = this.descarga_planta.cargas[idx_carga].descargas_horas[idx_descarga_hora].id;
      if(id){
        this.descargas_horas_ids_eliminar.push(id);
      }
      //let idx2 = this.descarga_planta.cargas[idx].descargas_horas.indexOf(hora);
      // console.log(`ìdx: ${idx}, idx2: ${idx2}`);
      let numero = 1;
      let horas = [...this.descarga_planta.cargas[idx_carga].descargas_horas]
        .filter((e) => e != hora)
        .map((h) => {
          h.numero = numero;
          numero++;
          return h;
        });

      this.descarga_planta.cargas[idx_carga].descargas_horas = horas;
      //let horas2 =
      //let horas2 = [...horas]
      // console.log("horas", horas);
      //console.log('horas2',horas2)
      //this.$set(this.descarga_planta.cargas[idx],'descargas_horas',horas)
    },

    calcularCargaEfectiva(lance) {
      if (lance.hora_inicio != null && lance.hora_termino != null) {
        let h2 = this.moment(lance.hora_termino);
        let h1 = this.moment(lance.hora_inicio);
        let seconds = h2.diff(h1, "seconds");
        let millis = this.moment
          .duration({ seconds: seconds })
          .asMilliseconds();
        let tiempo = this.moment.utc(millis).format("HH:mm");
        const index = this.carga.lances.indexOf(lance);
        lance.carga_efectiva = tiempo;
        this.$set(this.carga.lances, index, lance);
      }
    },
    cerrar_alert(tipo = "") {
      let alert = {
        mostrar: false,
        tipo: "",
        color_boton: "",
        mensaje: "",
        errores: [],
      };
      if (tipo == "carga") {
        this.alert_carga = alert;
      }
      if (tipo == "traslado") {
        this.alert_traslado = alert;
      }
      if (tipo == "descarga_planta") {
        this.alert_descarga_planta = alert;
      }
      if (tipo == "finalizar") {
        this.alert_finalizar = alert;
      }
    },
    generarKey(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },

    async finalizarServicioWellboat() {
      this.cerrar_alert("finalizar");

      let formData = new FormData();
      // let nf = this.archivos.length;
      //let i = 1;
      this.archivos.forEach((f) => {
        //formData.append("doc"+i, f);
        formData.append("files[]", f);
        //i++;
      });
      formData.append("partes_id", this.carga.partes_id);
      // formData.append('num_files', nf)
      // formData.append('archivos',this.archivos)

      formData.append("cargas_id", this.carga.id);
      formData.append("traslados_id", this.traslado.id);
      formData.append("descargas_plantas_id", this.descarga_planta.id);
      formData.append("tipopartes_id", this.parte.tipopartes_id);
      formData.append("folio", this.parte.folio);
      formData.append("wellboats_id", this.parte.wellboats_id);

      let url = `${this.base_url}bitacoras/finalizar`;

      await this.axios
        .post(url, formData, this.headers_file)
        .then((r) => {
          // console.log("r", r);
          this.alert_finalizar.mostrar = true;
          this.alert_finalizar.tipo = "success";
          this.alert_finalizar.color_boton = "green";
          this.alert_finalizar.mensaje = r.data.message;
        })
        .catch((e) => {
          // console.log("err", e);
          this.alert_finalizar.mostrar = true;
          this.alert_finalizar.tipo = "error";
          this.alert_finalizar.color_boton = "red";
          this.alert_finalizar.mensaje = e.response.data.message;
          this.alert_finalizar.errores = e.response.data.errors;
        });
    },
  },
  computed: {
    fechaGuiaFormatted() {
      return this.$options.filters.format_fecha(this.carga.fecha_despacho);
      //return this.formatDate(this.carga.fecha_despacho);
    },
    totalPeces() {
      let total = 0;
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.peces);
      });
      return total;
    },
    totalPeso() {
      if (this.totalBiomasa != 0 && this.totalPeces != 0) {
        return (this.totalBiomasa / this.totalPeces).toFixed(3);
      }
      return 0;
    },
    totalBiomasa() {
      let total = 0;
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.biomasa);
      });
      return total;
    },
    totalDensidad() {
      let total = 0;
      let m3_bodegas = 0;
      let ids = [];
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.biomasa);
        let index = ids.findIndex(id => id == b.bodegas_id);
        if(index == -1){
          let m3 = this.bodegas.find(bod => bod.id == b.bodegas_id)?.m3
          m3_bodegas += m3
          ids.push(b.bodegas_id)
        }
      });
      //let densidad = this.user.wellboat.sistema;
      return (total / m3_bodegas).toFixed(2);
    },
    finalizar_servicio() {
      return this.carga.temporal == 0 &&
        this.traslado.temporal == 0 &&
        this.descarga_planta.temporal == 0
        ? true
        : false;
    },
    ...mapState([
      "base_url",
      "storage_url",
      "headers_json",
      "headers_file",
      "user",
    ]),
  },
  components: {
    TicketBitacoraNew,
  },
};
</script>
